import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import doAPI from '../api';

// Async thunks for API calls

export const fetchInstructions = createAsyncThunk(
  'instructions/fetchInstructions',
  async (_, { rejectWithValue }) => {
    try {
      const res = await doAPI.getData('instruction/listview');
      return res.data.data.content;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createInstruction = createAsyncThunk(
  'instructions/createInstruction',
  async (values, { rejectWithValue }) => {
    try {
      await doAPI.postData('instruction/create', values);
      const res = await doAPI.getData('instruction/listview');
      return res.data.data.content;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInstruction = createAsyncThunk(
  'instructions/deleteInstruction',
  async (id, { rejectWithValue }) => {
    try {
      const res = await doAPI.deleteData(`instruction/destroy/${id}`);
      return { id, message: res.data.message };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const instructionsSlice = createSlice({
  name: 'instructions',
  initialState: {
    instructions: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch instructions
      .addCase(fetchInstructions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInstructions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.instructions = action.payload;
      })
      .addCase(fetchInstructions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Create instruction
      .addCase(createInstruction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInstruction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.instructions = action.payload;
      })
      .addCase(createInstruction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Delete instruction
      .addCase(deleteInstruction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInstruction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.instructions = state.instructions.filter(
          (instruction) => instruction.id !== action.payload.id
        );
      })
      .addCase(deleteInstruction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default instructionsSlice.reducer;
