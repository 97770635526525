// Create Redux Slice
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Define Initial State
const initialState = {
  loading: false,
  data: null,
  error: null,
};

// Write Async Thunk Action Creator
export const fetchPlayerDetails = createAsyncThunk("player/fetchDetails", async () => {
  try {
    const response = await doAPI.getData("player/details");
    if (response.status) {
      return response.data.data; // Assuming the API returns data directly
    } else {
      throw "Oops, please try again.";
    }
    //
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const playerDetailsSlice = createSlice({
  name: "playerDetails",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPlayerDetails.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlayerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPlayerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export action creators and reducer
// export const {} = playerDetailsSlice.actions;
export default playerDetailsSlice.reducer;
