import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocialMedia } from "../../../redux/socialMediaSlice";
// import { AiOutlineShareAlt } from "react-icons/ai";

export default function SocialBar({ variant }) {
  const dispatch = useDispatch();
  const socialMedia = useSelector(state => state.socialMedia.socialMedia);

  React.useEffect(() => {
    dispatch(fetchSocialMedia({ is_activated: 1 }));
  }, [dispatch]);

  const allSocialMedia = [
    { platform: "Whatsapp", icon: "whatsapp.png" },
    { platform: "Telegram", icon: "telegram.png" },
    { platform: "Facebook", icon: "facebook.png" },
    { platform: "Call", icon: "Call.png" },
    { platform: "Instagram", icon: "Instagram.png" },
    // Assuming 'Call' represents phone call
    // Add more social media platforms as needed
  ];

  return (
    <>
      {window.location.origin.includes("demo") ? (
        <div className={`socialMedia ${variant}`}>
          {allSocialMedia.map((platformData, index) => {
            const platformExists = socialMedia && socialMedia.some(i => i.platform === platformData.platform);
            if (platformExists || !socialMedia) {
              return (
                <a
                  key={index}
                  href={
                    platformExists
                      ? platformData.platform === "Whatsapp"
                        ? `https://wa.me/${socialMedia.find(i => i.platform === "Whatsapp")?.value}?text=Hello%20and%20Welcome`
                        : platformData.platform === "Instagram"
                          ? `https://www.instagram.com/direct/t/${
                              socialMedia.find(i => i.platform === "Instagram")?.value
                            }?text=Hello%20and%20Welcome`
                          : platformData.platform === "Telegram"
                            ? `https://t.me/${socialMedia.find(i => i.platform === "Telegram")?.value}?text=Hello%20and%20Welcome`
                            : platformData.platform === "Facebook"
                              ? `http://m.me/${socialMedia.find(i => i.platform === "Facebook")?.value}?text=Hello%20and%20Welcome`
                              : platformData.platform === "Call"
                                ? `tel:${socialMedia.find(i => i.platform === "Call")?.value}`
                                : ""
                      : "#"
                  }
                  target="_blank"
                  rel="noreferrer">
                  <img src={`../assets/images/${platformData.icon}`} />
                  <span className="socialLabel">&nbsp;{platformData.platform}</span>
                </a>
              );
            } else {
              // Display icon and platform name for platforms not present in socialMedia array
              return (
                <a key={index} href="#">
                  <img src={`../assets/images/${platformData.icon}`} />
                  <span className="socialLabel">&nbsp;{platformData.platform}</span>
                </a>
              );
            }
          })}
        </div>
      ) : (
        <div className={`socialMedia ${variant} rootBtn`}>
          {socialMedia &&
            socialMedia.map((i, index) => {
              return (
                <a
                  key={index}
                  href={
                    i.platform === "Whatsapp"
                      ? "https://wa.me/" + i.value + "?text=Hello"
                      : i.platform === "Telegram"
                        ? "https://t.me/" + i.value + "?text=Hello"
                        : i.platform === "Instagram"
                          ? "https://www.instagram.com/" + i.value
                          : i.platform === "Facebook"
                            ? "https://www.facebook.com/" + i.value
                            : i.platform === "Call"
                              ? "tel:" + i.value
                              : i.value
                  }
                  target="_blank"
                  rel="noreferrer">
                  <img src={`../assets/images/${i.platform}.png`} />

                  <span className="socialLabel">&nbsp;{i.platform}</span>
                </a>
              );
            })}
          {/* {variant === "variant1" && (
            <a href="#" className="h5 rootBtn m-0 mt-3">
              <AiOutlineShareAlt />
            </a>
          )} */}
        </div>
      )}
    </>
  );
}

SocialBar.propTypes = {
  // variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
  variant: PropTypes.string,
};
