import React from "react";
import { Animated } from "react-animated-css";
import { MdOutlineClose } from "react-icons/md";

export default function LtModal({ isOpen, onClose, heading, children, width = 500, direction = "center" }) {
  if (!isOpen) return null;

  const popup = () => (
    <div
      className={`${direction !== "center" && direction !== "bottom" ? "h-[calc(100vh)]" : "max-h-[calc(100vh-70px)] rounded-lg"} w-full overflow-auto bg-white shadow-lg`}>
      <button onClick={onClose} className={`h-[30px] w-[30px] position-absolute top-[15px] right-[15px] self-end rounded-3xl border-0 bg-slate-300 text-gray-500 hover:text-gray-700`}>
          <MdOutlineClose />
        </button>
      {heading&&<div className="flex items-center justify-between border-b p-3">
        <h2 className="mb-0  text-lg font-semibold">{heading ? heading : ""}</h2>
        
      </div>}

      <div className="border-top p-4">{children}</div>
    </div>
  );

  const getAnimation = direction => {
    switch (direction) {
      case "right":
        return { in: "slideInRight", out: "slideOutRight" };
      case "left":
        return { in: "slideInLeft", out: "slideOutLeft" };
      case "bottom":
        return { in: "slideInUp", out: "slideOutUp" };
      default:
        return { in: "zoomIn", out: "zoomOut" };
    }
  };

  const { in: animationIn, out: animationOut } = getAnimation(direction);

  return (
    <div
      className={`position-fixed fixed inset-0 z-[100000000] flex justify-center overflow-auto bg-black bg-opacity-50 py-[20px] pt-5 
      ${direction === "right" ? "dir-right" : direction === "left" ? "dir-left" : direction === "bottom" ? "dir-bottom" : ""}`}>
      <div className="position-fixed max-w-[calc(100%-30px)]" style={{ width }}>
        <Animated animationIn={animationIn} animationOut={animationOut} isVisible={true} animationInDuration={300} animationOutDuration={300}>
          {popup()}
        </Animated>
      </div>

      <style jsx="true">
        {`
          .dir-right > div {
            right: 0;
            top: 0;
            height: 100% !important;
          }
          .dir-left > div {
            left: 0;
            top: 0;
            height: 100% !important;
          }
          .dir-bottom > div {
            bottom: 0;
            position: fixed;
          }
        `}
      </style>
    </div>
  );
}
